body {
  background: #DAD7CD;
}

p {
  margin: 0;
  padding: 4px 0;
}

.upperCase {
  text-transform: uppercase;
}

.f3 {
  font-weight: bold;
  color: #C13C37;
}

.darkRed {
  color: #6A2135;
}

.lightRed {
  color: #C13C37;
}

.gold {
  color: #E38627;
}

.bold {
  font-weight: bold;
}

.invisibleRow {
  border-width: 0;
  border-spacing: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  padding: 0;
  margin: 0;
}
.invisibleCell {
  border:0;
}

.Header {
  background-color: #e38627;
  height: 160px;
  color: #6A2135;
}

.Header .sigmaLogo {
  font-size: 2em;
  margin-left: 20px;
}

.Header .sigmaLogo p {
  font-size:60px;
}

.Header .title {
  padding-top:20px;
  font-size: 35px;
  font-weight: bold;
  margin-left: 20px;
  white-space: nowrap;
}

.Header .userSettings {
  margin-right: 20px;
  padding-top: 20px;
  text-align: right;
}

.Header .userSettings .userButton {
  border: none;
  padding-right:42px;
  background-image: url(../assets/menuIcon3.png);
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: right center;
  background-color: transparent;
  font-size: 1.2em;
}

.Header .userSettings .userButton:hover {
  cursor: pointer;
  color: black;
}

.userMenu {
  position: absolute;
  top: 50px;
  right: 20px;
}

nav {
  padding-top:2px;
}

nav li {
  margin-right: 2%;
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position-y: 4px;
}

nav li a {
  text-decoration: none;
  color: darkred;
  padding: 8px 16px;
  padding-left: 32px;
}

nav li.dashboardIcon {
  background-image: url(../assets/chartDashColor.png);
}

nav li.optionIcon {
  background-image: url(../assets/optionIcon.png);
  background-size: 34px 34px;
  background-position-y: 2px;
}

nav li.underlyingIcon {
  background-image: url(../assets/shareIcon.png);
  background-size: 36px 36px;
  background-position-y: 1px;
}

nav li.aroiIcon {
  background-image: url(../assets/percentageCircleIcon.png);
}

nav li a:hover {
  color: black;
}

.appPage {
  padding: 0 2em;
}

.dashPage {
  padding-top: 2em;
}

.addItemButton {
  position: absolute;
  height: 70px;
  width: 70px;
  bottom: 20px;
  right: 20px;
  z-index: -9999;
  border-radius: 50%;
  background: black;
}

.addItemButton:hover {
  background: dimgrey;
}

.addItemButton p {
  text-align: center;
  margin: 0 auto;
  color: white;
  font-weight: bold;
  line-height: 100%;
  padding: 25px 0;
}

.addItemMenu {
  position: absolute;
  bottom: 85px;
}

p.dashboardLabel {
  font-size: 1.1em;
  padding: 0;
  margin: 0;
}

p.dashboardValue {
  font-weight: bold;
  font-size: 1.2em;
  padding-top: 8px;
  padding-bottom: 16px;
  margin: 0;
}

.dashboardRight p {
  text-align: right;
  float: right;
}

.dashboardChart {
  margin: 1em 3em 0 3em;
}

.dashboardChart .f4 {
  text-align: center;
  margin: 0 auto;
}

.dashboardChart svg {
  padding-bottom: 1rem;
}

.dashboardChart p {
  text-align: center;
}

.dashboardOptions {
  margin: 3rem 2rem;
}

.dashboardOptions p {
  padding-bottom: 8px;
}

.underlyingPositions {
  margin: 2em;
}

.App-link {
  color: #61dafb;
}

table {
  margin: 1rem;
  width: 90%;
  border-collapse: collapse;
}

thead th {
  text-align: center;
  padding-right: 0.35em;
}

thead button {
  border: 2px solid #6A2135;;
  background: none;
  border-radius: 10px;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  margin-bottom: 1px;
}
button.ascending, button.descending {
  background: #C13C37;
}

thead button.ascending::after {
  content: '\02C4';
  display: inline-block;
  margin-left: 0.25em;
}

thead button.descending::after {
  content: '\02C5';
  display: inline-block;
  margin-left: 0.25em;
}

tbody td {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

tbody td {
  text-align: center;
}

tbody tr:hover {
  background-color: #eee;
}

label.aroiCalc {
  font-size: 1.2em;
}

label p.required {
  margin: 0;
}

label p.required::after {
  content: "\002A";
  padding-left: 4px;
  color: darkred;
}

fieldset {
  border:none;
}

input, select {
  border-radius: 5px;
}

.dateInput {
  font-size: 1.2em;
  width: 75%;
  text-align: center;
}

.numberInput {
  font-size: 1.2em;
  width: 40%;
  margin: 0 0 0 4px;
  padding:8px;
  text-align: center;
}

button.add {
  border-radius: 15px;
}


.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0,0,0,.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (min-width: 600px){
  .dashboardChart {
    width:75%;
    margin:0 auto;
  }
}

@media (min-width: 768px){
  .dashboardChart {
    width:60%;
    margin:0 auto;
  }

  .aroiCalcForm {
    width: 650px;
  }

  /*
  TODO main nav breakpoint....
   */
}

@media (min-width: 1080px){
  .dashboardChart {
    width:40%;
  }
}